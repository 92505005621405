import type { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useCallback } from 'react';
import type { Tournament } from '../../@types/api';
import { QUERY_KEYS, PUBLISH_MODEL_OPTIONS, TIME_DELAY_OPTIONS } from '../../constants';
import { createSportIconColumn } from '../../helpers/columns';
import { defaultColumnsResults } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import SelectCell from '../molecules/SelectCell';
import TableTemplate from '../templates/TableTemplate';

const PublishEventsPage = () => {
  const {
    data: resultsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<Tournament>('tournaments', {
    page: 1,
    limit: 25,
    withSettings: true,
  });
  const { updateData } = useMutateData('tournaments', [QUERY_KEYS.tournaments]);
  const { handleSort } = useSort(changeQuery);

  const handlePatchTournament = useCallback(
    (id: string, value: Record<string, string | number>) => {
      // if switching to automatic model, set default time delay
      if (value.updateType === 'automatic') {
        value.updateTimeDelayInMinutes = 10;
      }
      updateData(id, value);
    },
    [updateData]
  );

  const columns: GridColDef[] = [
    createSportIconColumn<Tournament>(),
    createColumn('sportName', 'Sport', {
      valueGetter: (params: GridValueGetterParams<Tournament>) => params.row.sport.name,
    }),
    createColumn('competitionName', 'Competition', {
      valueGetter: (params: GridValueGetterParams<Tournament>) => params.row.competition.name,
    }),
    createColumn('name', 'Tournament'),
    createColumn('updateType', 'Model', {
      renderCell: (params: GridRenderCellParams<Tournament>) => {
        return (
          <SelectCell
            defaultSelectValue={params.row.updateType}
            options={PUBLISH_MODEL_OPTIONS}
            onChange={(value: string | number) =>
              handlePatchTournament(params.row.id || '', {
                updateType: value,
              })
            }
          />
        );
      },
    }),
    createColumn('updateTimeDelayInMinutes', 'Time delay', {
      renderCell: (params: GridRenderCellParams<Tournament>) => {
        if (params.row.updateType !== 'automatic') {
          return null;
        }

        return (
          <SelectCell
            defaultSelectValue={params.row.updateTimeDelayInMinutes}
            options={TIME_DELAY_OPTIONS}
            onChange={(value: string | number) =>
              handlePatchTournament(params.row.id || '', {
                updateTimeDelayInMinutes: value,
              })
            }
          />
        );
      },
    }),
  ];

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  return (
    <TableTemplate
      rows={resultsData?.items || []}
      columns={columns}
      rowCount={resultsData?.count || 0}
      loading={isFetching || isLoading}
      defaultVisibleColumns={defaultColumnsResults}
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
    />
  );
};

export default PublishEventsPage;
